<template>
  <div class="co-flex-row co-justify-end co-items-center co-w-full pa-2">
    <v-progress-circular
      v-if="value > 0 && value < 100"
      :value="value"
      size="28"
      color="blue"
      indeterminate
    >
      <span class="co-text-2xs white--text">
        {{ value }}
      </span>
    </v-progress-circular>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Number,
        default: 0
      }
    },
    watch: {
      value: {
        handler (val) {
          // console.log('progress val=%o', val)
        },
        immediate: true
      }
    }
  }
</script>
